<template>
  <form
    class="appForm"
    @submit.prevent="$emit('submit')"
  >
    <div
      class="formHeader"
      style="width: 100%;text-align: center;"
    >
      <div class="appForm__title">
        {{ title }}
      </div>
      <div
        class="appForm__description"
        style="width: 100%;"
      >
        {{ description }}
      </div>
    </div>
    <div class="appForm__content">
      <slot />
    </div>
    <div
      v-if="message"
      class="appForm__message"
      @click="$emit(msgEvent)"
    >
      {{ message }}
    </div>
  </form>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    msgEvent: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.appForm {
  color: #7a615a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    text-align: center;
    position: relative;
    top: -40px;
    font: {
      size: 55px;
    }
  }

  &__description {
    position: relative;
    top: -10px;
    text-align: center;
    font: {
      size: 18px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__message {
    margin-top: 30px;
    cursor: pointer;
    color: #ff4f79;
    font: {
      size: 18px;
    }
  }
}

@media screen and (max-width: 850px) {
  .appForm {
    &__title {
      font: {
        size: 45px;
      }
    }
  }
}

/*@media screen and (max-width: 850px) {*/
/*	.appForm {*/
/*		&__title {*/
/*			top: -80px;*/
/*		}*/
/**/
/*		&__description {*/
/*			top: -70px;*/
/*		}*/
/**/
/*		&__content {*/
/*			position: relative;*/
/*			top: -60px;*/
/*		}*/
/*}*/
/*}*/

@media screen and (max-height: 650px) {
  .appForm {
    &__title {
      top: -120px;
    }

    &__description {
      top: -100px;
    }

    &__content {
      position: relative;
      top: -20px;
    }
  }
}

@media screen and (max-height: 750px) {
  .appForm {
    &__title {
      top: -40px;
    }

    &__description {
      top: -20px;
    }
  }
}
</style>
