<template>
  <div :class="'appModal ' + (isOpen ? 'appModal-open' : '')">
    <!-- <div
      class="appModal__close"
      @click="close"
      style="z-index: 999"
    /> -->

    <div
      id="modal"
      class="appModal__content"
      style="position: relative !important;padding: 0 !important;"
      :style="{ width: widthSize, height: heightSize }"
    >
      <header
        v-if="hasHeader"
        id="modalHeader"
        class="desktopModal"
      >
        <!-- <div
          class="col-md-3"
          style="display: flex;align-items: center;justify-content: flex-start;"
        > -->
        <!-- <AppButton
            v-if="hasButton"
            style="height: 44px !important;padding: 0 35px;font-size: 15px;"
            :name="buttonText"
            :color="buttonColor"
            class="modalButton"
            :mini="true"
            @click="event"
          /> -->
        <!-- </div>
        <div
          class="col-md-6"
          style="display: flex;justify-content: center;;"
        > -->
        <div
          v-if="hasText"
          class="modalText"
        >
          <div class="modalTextTitle">
            {{ title }}
          </div>
          <!-- <div
              v-if="description"
              style="margin-top: 5px;
    font-weight: 400;
    font-size: 15px;text-align: center;
            "
            >
              {{ description }}
            </div> -->
        </div>
        <!-- </div> -->
        <!-- <div
          class="col-md-3"
          style="display: flex;justify-content: flex-end;align-items: center;"
        > -->
        <div>
          <div
            class="appModal__close"
            style="z-index: 999;position: static !important;align-self: right;"
            @click="close"
          />
        </div>
        <!-- </div> -->
      </header>
      <header
        v-if="hasHeader"
        id="modalHeader"
        class="mobileModal"
      >
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <!-- <AppButton
            v-if="hasButton"
            :name="buttonText"
            :color="buttonColor"
            class="modalButton"
            :mini="true"
            @click="event"
          /> -->
          <div style="display: flex;justify-content: flex-end;align-items: center;width: 100%;">
            <div
              class="appModal__close"
              style="z-index: 999;position: static !important;align-self: right;"
              @click="close"
            />
          </div>
        </div>
        <div class="mobileModalText">
          <div
            v-if="hasText"
            class="modalText"
          >
            <div class="modalTextTitle">
              {{ title }}
            </div>
            <div
              v-if="description"
              class="modalTextDescription"
            >
              {{ description }}
            </div>
          </div>
        </div>
      </header>
      <div
        v-if="hasError"
        class="modalNotification"
      >
        {{ errorMessage }}
      </div>
      <div
        :style="flexProp"
        class="modalInner"
      >
        <slot />
      </div>
      <div class="modalActionButton">
        <AppButton
          v-if="hasButton"
          class="modalActionButtonSmall"
          :name="buttonText"
          :color="buttonColor"
          :mini="true"
          @click="event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "../components/AppButton";
export default {
  components: {
    AppButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Boolean,
      default: false,
    },
    widthSize: {
      type: String,
      default: "",
    },
    height: {
      type: Boolean,
      default: false,
    },
    heightSize: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    alignItems: {
      type: String,
      default: "center",
    },
    justifyContent: {
      type: String,
      default: "center",
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasText: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "",
    },
    buttonColor: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    flexProp() {
      return "align-items: " + this.alignItems;
    },
  },
  methods: {
    close() {
      this.$emit("close");
      //                this.$el.querySelector('.appModal__content').classList.remove('zoomInUp');
      //                this.$el.querySelector('.appModal__content').classList.add('zoomOutUp');
    },
    event() {
      this.$emit("btnEvent");
    },
  },
};
</script>

<style lang="scss" scoped>
.appModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  min-height: 10vh;
  color: #7a615a;
  // overflow-y: auto;
  // overflow-x: hidden;
  background-color: rgba(black, 0.2);
  backdrop-filter: saturate(180%) blur(15px);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999999 !important;
  animation: fade 100ms ease;

  &__content {
    max-height: 88vh;
    width: 770px;
    // background-color: #f8f5ed;
    border-radius: 40px;
    padding: 70px;
    box-sizing: border-box;
    z-index: -1;
    position: relative;
    overflow: auto;
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close,
  &__close__content {
    position: static;
    top: 25px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 12px;
    background-color: #ff4f79;
    background-image: url("../assets/images/cancel.png");
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: center;
    z-index: 0;
    cursor: pointer;

    &__content {
      display: none;
    }
  }

  &-open {
    display: flex;
    z-index: 4;
  }
}
.appModal__content::-webkit-scrollbar {
  border-radius: 50px;
  width: 10px;
  background-color: transparent;
  left: -20px;
}

.appModal__content::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border: 2px solid #e5e5e5;
  border-radius: 100px;
}

.mobileModal {
  display: none !important;
}

#modalHeader {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  // background: #f8f5ed;
  background: #ffffff;
  // padding: 14px 20px 0 20px;
  padding: 20px;
  z-index: 99999;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
}
.modalButton {
  margin: 0 !important;
}
.modalText {
  color: #7a615a;
}

.mobileModalText {
  display: flex;
  justify-content: center;
}

.modalNotification {
  background: #ff4f79;
  width: 100%;
  padding: 20px;
  text-align: center;
  color: white;
  position: sticky;
  top: 82px;
  z-index: 999;
  display: block;
}
.modalInner {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.modalTextTitle {
  font-size: 25px;
  font-weight: 200;
  text-align: center;
  
}

.modalTextDescription {
  margin-top: 5px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
}

/******************************************* MEDIA ******************************************/

@media screen and (max-width: 850px) {
  .appModal__content::-webkit-scrollbar {
    display: none;
  }

  .mobileModal {
    display: block !important;
  }

  .desktopModal .appButton {
    font-size: 15px !important;
  }

  #modalHeader {
    padding: 0;
  }

  .disclaimer {
    text-align: center;
  }

  .appModal__close {
    display: block !important;
    height: 35px !important;

    // width: 100% !important;
    background-size: 19px !important;
    border-radius: 0;
    border-bottom-left-radius: 10px;
  }

  .modalText {
    // margin-top: 15px;
    padding-bottom: 10px;
  }

  .modalTextTitle {
    font-size: 16px;
  }

  .modalTextDescription {
    font-size: 12px;
    margin-top: 5px !important;
    padding: 0 25px;
  }

  .appForm__avatarText {
    font-size: 14px !important;
    margin-top: 20px !important;
  }

  .appAvatars__custom__active > img,
  .appAvatars__custom > img {
    margin-top: 0px !important;
    margin-left: 4px !important;
  }

  // .appButton {
  //   margin-bottom: 0 !important;
  //   width: 100% !important;
  //   height: 35px !important;
  //   padding: 0 !important;
  //   border-radius: 0;
  //   order: 1;
  // }

  .desktopModal {
    display: none !important;
  }

  .modalInner .appInput {
    width: 100% !important;
  }

  .appModal {
    z-index: 5;
    backdrop-filter: saturate(180%) blur(15px);
    &__content {
      max-height: 100vh;
      width: 100vw;
      height: 100vh;
      border-radius: 0px;
      padding: 120px 20px;
    }

    &__close {
      display: none;

      &__content {
        display: block;
        top: -60px;
        left: 50%;
        transform: translateX(-20px);
      }
    }
  }
}

@media screen and (max-height: 650px) {
  .appModal {
    backdrop-filter: saturate(180%) blur(15px);
    &__content {
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 800px) {
  .appModal__content {
    display: block !important;
  }
  .appModal__close__content {
    z-index: 99999 !important;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
