<template>
  <!-- <div style="display: flex; flex-direction: column">
    <input
      min="0"
      :id="label"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="appInput Input-text"
      :class="{ diceIcon: dice }"
      @input="$emit('input', $event.target.value)"
    />
    <label :for="label" class="Input-label">{{ name }}</label>
  </div> -->

  <!----- :style="[hasLoader ? {'display' : 'flex', 'justify-content' : 'space-between', 'align-items' : 'center'} : {}]" ---->
  <div class="form-row">
    <label style="display: flex; align-items: center" :style="labelStyle">
      {{ name }}
      <svg
        v-if="hasLoader"
        width="65"
        height="10"
        viewBox="0 0 120 30"
        xmlns="http://www.w3.org/2000/svg"
        fill="#757575"
      >
        <circle cx="15" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="60" cy="15" r="9" fill-opacity="0.3">
          <animate
            attributeName="r"
            from="9"
            to="9"
            begin="0s"
            dur="0.8s"
            values="9;15;9"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="0.5"
            to="0.5"
            begin="0s"
            dur="0.8s"
            values=".5;1;.5"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="105" cy="15" r="15">
          <animate
            attributeName="r"
            from="15"
            to="15"
            begin="0s"
            dur="0.8s"
            values="15;9;15"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="fill-opacity"
            from="1"
            to="1"
            begin="0s"
            dur="0.8s"
            values="1;.5;1"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </label>
    <input
      class="app-input"
      :ref="inputRef"
      :min="min"
      :max="max"
      autocomplete="off"
      :type="type"
      :value="value"
      :maxlength="max"
      :accept="accept"
      :required="required"
      :placeholder="placeholder"
      :class="{ diceIcon: dice }"
      :style="[
        type == 'file'
          ? {
              padding: 0,
              height: 'auto',
              border: 'none',
              'border-radius': 0,
              background: '#fff',
            }
          : {},
      ]"
      @input="$emit('input', $event.target.value)"
      @change="$emit('change', $event)"
      @keyup="$emit('keyup', $event)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      value: {
        default: "",
      },
      id: {
        default: "",
      },
      label: {
        default: "",
      },
      name: {
        default: "",
      },
      dice: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: false,
      },
      max: {
        type: Number,
      },
      min: {
        type: Number,
      },
      accept: {
        type: String,
      },
      labelAlign: {
        type: String,
      },
      maxlength: {},
      inputRef: {},
      hasLoader: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      labelStyle() {
        if (this.labelAlign == "left") {
          return "justify-content: flex-start";
        } else if (this.labelAlign == "center") {
          return "justify-content: center";
        } else if (this.labelAlign == "right") {
          return "justify-content: flex-end";
        } else {
          return "justify-content: space-between";
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  // .appInput {
  //   width: 570px;
  //   height: 50px;
  //   margin-bottom: 20px;
  //   margin-top: 10px;
  //   color: #7a615a;
  //   border: 1px solid #e5e5e5;
  //   box-sizing: border-box;
  //   border-radius: 74px;
  //   outline: none;
  //   padding: 0px 30px;

  //   font: {
  //     // family: Roboto;
  //     family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //     size: 16px;
  //   }
  // }

  // ::placeholder {
  //   font: {
  //     style: italic;
  //     size: 16px;
  //   }

  //   color: #a6a6a6;
  //   opacity: 1;
  // }

  // .inputLabel {
  //   color: #a6a6a6;
  // }

  // .appInput[type="date"]::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   display: none;
  // }

  // .appInput[type="date"]::-webkit-calendar-picker-indicator {
  //   color: rgba(0, 0, 0, 0);
  //   opacity: 1;
  //   display: block;
  //   background: url("../assets/images/iconCalendar.png") no-repeat;
  //   width: 25px;
  //   height: 25px;
  //   border-width: thin;
  // }

  // input[type="number"]::-webkit-outer-spin-button,
  // input[type="number"]::-webkit-inner-spin-button {
  //   -webkit-appearance: none;
  //   background: #fff url("../assets/images/numberArrow.png") no-repeat center
  //     center;
  //   width: 16px;
  //   opacity: 1;
  //   /* shows Spin Buttons per default (Chrome >= 39) */
  //   position: absolute;
  //   top: 0;
  //   right: 30px;
  //   bottom: 0;
  // }
  // /* Override browser form filling */
  // input:-webkit-autofill {
  //   background: black;
  //   color: red;
  // }

  // ::placeholder,
  // :-ms-input-placeholder,
  // ::-ms-input-placeholder {
  //   font: {
  //     style: italic;
  //     size: 12px;
  //   }

  //   color: #a6a6a6;
  //   opacity: 1;
  // }

  // @media screen and (max-width: 850px) {
  //   .appInput {
  //     width: calc(100vw - 40px);
  //   }
  // }

  // .diceIcon {
  //   background: url("../assets/images/iconDice.png") no-repeat;
  //   background-size: 25px;
  //   background-position: 95%;
  //   padding-right: 65px;
  // }

  .form-row {
    margin-bottom: 1rem;
    position: relative;
    // background: #fafafa;
  }
  .form-row:last-child {
    margin-bottom: 0;
  }
  .form-row input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #dedede;
    border-radius: 5px;
    outline: 0;
    height: 50px;
    color: #7a615a;
    font-size: 14px;
    background: #fafafa;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    -webkit-appearance: none;
  }
  .form-row label {
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary);
    margin-bottom: 5px;
  }
  // .form-row input + label[data-placeholder] {
  //   pointer-events: none;
  //   font-size: 12px;
  //   color: #7a615a;
  //   font-weight: 600;
  // }
  // .form-row input + label[data-placeholder]:after {
  //   content: attr(data-placeholder);
  //   display: block;
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   -webkit-transform: translate(0%, -50%);
  //   transform: translate(0%, -50%);
  //   // padding: 0 1rem;
  //   background-color: rgba(255, 255, 255, 0);
  // }
  // .form-row input:focus,
  // .form-row input:valid,
  // .form-row input:focus + label[data-placeholder]:after,
  // .form-row input:valid + label[data-placeholder]:after {
  //   color: #36d5f2;
  // }
  // .form-row input + label[data-placeholder]:after,
  // .form-row input:valid + label[data-placeholder]:after {
  //   -webkit-transform: translate(-5%, -280%) scale(0.9, 0.9);
  //   transform: translate(-5%, -280%) scale(0.9, 0.9);
  //   color: var(--secondary);
  //   // background-color: white;
  // }

  .app-input {
    height: 44px !important;
    color: var(--main) !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100px !important;
    padding: 5px 30px;
    font-size: 14px;
    width: 100%;
    background: #ebebeb !important;
    color: #806157 !important;
    display: block;
    border: none !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: unset !important;
  }

  .app-input::placeholder {
    color: #806157;
    font-style: italic;
  }
</style>
