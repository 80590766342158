var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-row"},[_c('label',{staticStyle:{"display":"flex","align-items":"center"},style:(_vm.labelStyle)},[_vm._v(" "+_vm._s(_vm.name)+" "),(_vm.hasLoader)?_c('svg',{attrs:{"width":"65","height":"10","viewBox":"0 0 120 30","xmlns":"http://www.w3.org/2000/svg","fill":"#757575"}},[_c('circle',{attrs:{"cx":"15","cy":"15","r":"15"}},[_c('animate',{attrs:{"attributeName":"r","from":"15","to":"15","begin":"0s","dur":"0.8s","values":"15;9;15","calcMode":"linear","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"fill-opacity","from":"1","to":"1","begin":"0s","dur":"0.8s","values":"1;.5;1","calcMode":"linear","repeatCount":"indefinite"}})]),_c('circle',{attrs:{"cx":"60","cy":"15","r":"9","fill-opacity":"0.3"}},[_c('animate',{attrs:{"attributeName":"r","from":"9","to":"9","begin":"0s","dur":"0.8s","values":"9;15;9","calcMode":"linear","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"fill-opacity","from":"0.5","to":"0.5","begin":"0s","dur":"0.8s","values":".5;1;.5","calcMode":"linear","repeatCount":"indefinite"}})]),_c('circle',{attrs:{"cx":"105","cy":"15","r":"15"}},[_c('animate',{attrs:{"attributeName":"r","from":"15","to":"15","begin":"0s","dur":"0.8s","values":"15;9;15","calcMode":"linear","repeatCount":"indefinite"}}),_c('animate',{attrs:{"attributeName":"fill-opacity","from":"1","to":"1","begin":"0s","dur":"0.8s","values":"1;.5;1","calcMode":"linear","repeatCount":"indefinite"}})])]):_vm._e()]),_c('input',{ref:_vm.inputRef,staticClass:"app-input",class:{ diceIcon: _vm.dice },style:([
      _vm.type == 'file'
        ? {
            padding: 0,
            height: 'auto',
            border: 'none',
            'border-radius': 0,
            background: '#fff',
          }
        : {},
    ]),attrs:{"min":_vm.min,"max":_vm.max,"autocomplete":"off","type":_vm.type,"maxlength":_vm.max,"accept":_vm.accept,"required":_vm.required,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"change":function($event){return _vm.$emit('change', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }